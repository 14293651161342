import Vue from "vue";
import Header from "@/views/Admin/Commons/Header.vue";
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import ItemTab from "@/views/Admin/Datorama/Report/Tabs/index.vue";
import Alertize from "@/components/Alertize.vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "OverviewLineItem",
	props: {},
	components: { Header, CreateTabs, Alertize, ItemTab },
	data: () => ({
		title: "ReportLogicDatorama",
		currentTab: 0,
		expanded: false,
		items: [],
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			await this.handlerLoadPage();
		});
	},
	computed: {
		...mapGetters("loading", ["isLoading"]),
		...mapGetters("profile", ["getProfileData"]),
		getItems() {
			return this.items;
		},
		hasItems() {
			return !isEmpty(this.items);
		},
		getItemSelected() {
			return this.items.find(
				(i: { key: any }) => i.key === this.currentTab
			);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("dashboard", ["getReportList"]),
		handlerSelectedTabIndex(index: any) {
			this.currentTab = index;
		},
		handlerExpanded(event: any) {
			this.expanded = !this.expanded;
		},
		async handlerLoadPage(){
			await this.setLoadingData(TypeLoading.loading);
			const reportList: Array<any> = await this.getReportList();
			this.items = reportList;
			await this.setLoadingData();
		}
	},
	watch: {
		async "getProfileData"(val){
			await this.handlerLoadPage();
		}
	},
});
