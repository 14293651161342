import Vue from "vue";
import { getProfile } from "@/middlewares/utils";
import DatoramaIFrame from "@/views/Admin/Datorama/Report/Iframe.vue";
import ButtonToggle from "@/views/Admin/Datorama/Report/Tabs/Toggle.vue";
import DatoramaBanner from "@/views/Admin/Datorama/Report/Banner.vue";

export default Vue.extend({
	name: "ItemTabLogicDatorama",
	props: {
		expanded: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: { DatoramaIFrame, ButtonToggle, DatoramaBanner },
	data: () => ({
		logo: "",
		width: "100%",
		height: "1200px",
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			const profile = await getProfile();
			this.logo = profile?.logo ?? "";
		});
	},
	computed: {},
	methods: {
		handlerClick(event: any) {
			this.$emit("toggle-expanded");
		},
	},
	watch: {},
});
