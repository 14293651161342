
import Vue from "vue";
//@ts-ignore
import Logo from "@/components/Admin/AppBar/Logo.vue";
import { mapGetters } from "vuex";
// import { mapGetters } from "vuex";
export default Vue.extend({
	name: "DashboardBannerDatorama",
	props: {
		title: {
			type: String,
			default: "Title",
		},
		icon: {
			type: String,
			default: "",
		},
	},
	components: { Logo },
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("profile", ["logo"]),
	},
	methods: {},
	watch: {},
});
